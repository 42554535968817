import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faHome, faSearch, faPlus, faTimes, } from '@fortawesome/free-solid-svg-icons'
import CommonHelper from '../../helpers/common'
import SelectUnit from '../../components/controls/select-unit';
import TextAreaInput from '../../components/controls/textarea-input';
import InputTextUnit from '../../components/controls/input-text-unit'
import DateInput from '../../components/controls/date-input'
import AutocompleteInput from '../../components/controls/autocomplete-input'
import ValidateMessage from '../../components/controls/validate-msg'
import AdminNewsSearch from './search'
import { organizerActions } from '../../_store/organizer.slice'
import newsApi from '../../api/newsApi'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';


const initial = {
    "appSite": process.env.REACT_APP_CODE,
    id: 0,
    seasonId: 0,
    categoryId: 0,
    title: '',
    shortContent: '',
    content: '',
    image: '',
    url: '',
    publishDate: '',
    publisher: '',
    activated: "1",
    extraValue1: '',
    extraValue2: '',
    extraValue3: '',
    extraValue4: '',
    extraValue5: '',
    customOrder: 0,
    useCustomOrder: false,
}

function AdminNewsPage() {
    const dispatch = useDispatch();
    const [state, setState] = useState(initial);
    const fileUpload = useRef(null);
    const [{ publishDate, showInHome }, setSelected] = useState({
        publishDate: null,
        showInHome: false
    })
    const [{ stateError, errorMsg, isLoading, isError, isSuccess, isEdit, onSearch, lst_venues }, setCommonState] = useState({
        stateError: {
            title: false,
            content: false,
        },
        errorMsg: {
            title: "",
            content: "",
        },
        isLoading: true,
        isError: false,
        isSuccess: false,
        isEdit: false,
        onSearch: false,
    })
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        var _data = {
                            file
                        };
                        var response = newsApi.upload_news_image_to_editor(_data);
                        response.then((res) => {
                            if (res.status === 200) {
                                if (res.data.code === 202 && res.data.data != null) {
                                    resolve({ default: `${process.env.REACT_APP_EXHIBITOR_IMAGE}${res.data.data.img_url}` })
                                }
                            }
                        }).catch((err) => {
                            reject(err);
                        })
                    })
                })
            }
        }
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }
    const upload_news_image = (e) => {
        const { name, files } = e.target;
        if (files != null && files.length > 0) {
            var _data = {
                file: files[0]
            };
            var response = newsApi.upload_news_image(_data);
            response.then((res) => {
                console.log(res)
                if (res.status === 200) {
                    if (res.data.code === 202 && res.data.data != null) {
                        setState((prev) => ({ ...prev, image: res.data.data.img_url }))
                    }
                }
            })
        }
    };
    useEffect(() => {
        if (CommonHelper.is_not_empty(window.location.search)) {
            setCommonState((prev) => ({ ...prev, onSearch: false }))
            const response = newsApi.get(window.location.search);
            dispatch(organizerActions.set_loading());
            response.then((res) => {
                if (res != null) {
                    if (res.code == 506) {
                        setCommonState((prev) => ({ ...prev, isLoading: true }));
                        alert("Session is expired. Please sign in again!")
                        const timer = setTimeout(() => {
                            window.location.href = "/admin/login"
                        }, 4000);
                        return () => clearTimeout(timer);
                    } else {
                        dispatch(organizerActions.set_loading());
                        set_data(res.data);
                    }
                }
            })
        }
    }, [])
    useEffect(() => {
        var extraValue3 = "";
        var _publishDate = "";
        if (showInHome) {
            extraValue3 = JSON.stringify({ showInHome })
        }
        if (publishDate != null) {
            _publishDate = CommonHelper.formatToYYYYMMDDHHMMSS(publishDate);
        }
        setState((prev) => ({
            ...prev, extraValue3,
            publishDate: _publishDate
        }));
    }, [showInHome, publishDate])
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({
            ...prev,
            [name]: value,
        }))
        validate_required(e);
    }
    const set_data = (data) => {
        if (data != null) {
            var publishDate = null;
            var showInHome = false;
            if (CommonHelper.is_not_empty(data.extraValue3)) {
                var objExtra3 = JSON.parse(data.extraValue3);
                if (objExtra3 != null) {
                    showInHome = objExtra3.showInHome;
                }
            }
            if (CommonHelper.is_not_empty(data.publishDate)) {
                publishDate = CommonHelper.parseStringToDate(data.publishDate, "yyyyMMdd HHmmss")
            }
            setSelected((prev) => ({
                ...prev,
                publishDate,
                showInHome,
            }));
            setCommonState((prev) => ({
                ...prev,
                isEdit: false
            }))
            setState((prev) => ({
                ...prev,
                id: data.id,
                seasonId: data.seasonId,
                categoryId: data.categoryId,
                title: data.title,
                shortContent: data.shortContent,
                content: data.content,
                image: data.image,
                url: data.url,
                publishDate: data.publishDate,
                publisher: data.publisher,
                activated: data.activated ? "1" : "0",
                isDeleted: data.isDeleted,
                extraValue1: data.extraValue1,
                extraValue2: data.extraValue2,
                extraValue3: data.extraValue3,
                extraValue4: data.extraValue4,
                extraValue5: data.extraValue5,
                customOrder: data.customOrder,
                useCustomOrder: data.useCustomOrder,
            }))
        }
    }
    const validate_required = (e) => {
        const { name, value } = e.target;
        var _arr = [];
        Object.keys({ ...stateError }).map((item) => {
            _arr.push(item);
        })
        var _idx = _arr.indexOf(name);
        if (_idx >= 0) {
            setCommonState((prev) => ({
                ...prev,
                stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
            }))
        }
    }
    const validate_form = () => {
        var flag = true;
        var _arr = [];
        Object.keys({ ...stateError }).map((item) => {
            _arr.push(item);
        })
        var _data = { ...state };
        //console.log(_data);
        var _objError = { ...stateError };
        _arr.forEach((item, i) => {
            if (CommonHelper.is_empty(_data[item])) {
                _objError[item] = true;
                flag = false;
            }
        })
        setCommonState((prev) => ({
            ...prev,
            stateError: _objError,
        }))
        return flag;
    }
    const handle_search = () => {
        setCommonState((prev) => ({ ...prev, onSearch: true }))
    }
    const handle_submit = () => {
        var _data = { ...state };
        _data.activated = _data.activated == "1";
        if (validate_form()) {
            dispatch(organizerActions.set_loading());
            const response = newsApi.post(_data);
            response.then((res) => {
                if (res.code == 506) {
                    setCommonState((prev) => ({ ...prev, isLoading: true }));
                    alert("Session is expired. Please sign in again!")
                    const timer = setTimeout(() => {
                        window.location.href = "/admin/login"
                    }, 4000);
                    return () => clearTimeout(timer);
                } else {
                    dispatch(organizerActions.set_loading());
                    if (res.code == 204) {
                        setCommonState((prev) => ({ ...prev, isSuccess: true, isError: false }));
                        setState((prev) => ({ ...prev, id: res.data.id }))
                    } else {
                        setCommonState((prev) => ({ ...prev, isSuccess: false, isError: true }));
                    }
                }
            })
        }
    }
    const handle_reset = () => {
        setSelected((prev) => ({
            ...prev,
            publishDate: null,
            showInHome: false,
        }));
        setCommonState((prev) => ({
            ...prev,
            isEdit: false
        }))
        setState((prev) => ({
            ...prev,
            id: initial.id,
            seasonId: initial.seasonId,
            categoryId: initial.categoryId,
            title: initial.title,
            shortContent: initial.shortContent,
            content: initial.content,
            image: initial.image,
            url: initial.url,
            publishDate: initial.publishDate,
            publisher: initial.publisher,
            activated: initial.activated,
            isDeleted: initial.isDeleted,
            extraValue1: initial.extraValue1,
            extraValue2: initial.extraValue2,
            extraValue3: initial.extraValue3,
            extraValue4: initial.extraValue4,
            extraValue5: initial.extraValue5,
            customOrder: initial.customOrder,
            useCustomOrder: initial.useCustomOrder,
        }))
    }
    const remove_image = () => {
        setState((prev) => ({ ...prev, image: "" }))
    }
    const handleUpload = () => {
        fileUpload.current.click()
    };
    return (
        <>
            <div className='p-3 bg-white'>
                <div className='breadscum w-full'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/news"}>News </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex'>
                    <div className='w-2/3'>
                        <h6 className='text-color-primary font-semibold text-xl'>News</h6>
                    </div>
                    <div className='w-1/3 text-right'>
                        <a className='cursor-pointer' onClick={handle_search}>
                            <FontAwesomeIcon icon={faSearch} />
                        </a>
                    </div>
                </div>
                <div className='block sm:flex sm:flex-wrap'>
                    <div className='w-full sm:w-3/4 mt-4'>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.title} name='title'
                                label='Title' required={true} isError={stateError.title}
                                errorMsg={errorMsg.title} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='font-semibold'>Short content</label>
                            <TextAreaInput name="shortContent" className={"h-32 resize-none"}
                                value={state.shortContent} onChange={handle_change} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='font-semibold'>Content</label>
                            <div className='min-h-[500px]'>
                                <CKEditor
                                    config={{
                                        extraPlugins: [uploadPlugin],
                                        htmlSupport: {
                                            allow: [
                                                { attributes: true, classes: true, styles: true }
                                            ],
                                        },
                                        style: {
                                            definitions: [{
                                                name: '1.5 lines',
                                                element: 'p',
                                                classes: ["leading-6"]
                                            },
                                            /*{
                                                name: 'image center',
                                                element: 'img',
                                                classes: ['text-center mx-auto']
                                            },*/
                                            {
                                                name: 'image center',
                                                element: 'figure',
                                                classes: ['text-center']
                                            },
                                            ]
                                        },
                                        //toolbar: ["sourceEditing", "heading", "|", "bold", "italic", "|", "alignment", '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent', "|", 'uploadImage',],
                                    }}
                                    editor={Editor}
                                    data={state.content}
                                    onChange={(event, editor) => {
                                        setState((prev) => ({ ...prev, content: editor.getData() }))
                                    }}
                                />
                            </div>
                            <ValidateMessage msgType="required" isShow={stateError.content} />
                        </div>
                        <div className='w-full mt-5 px-2 sm:px-4'>
                            <label className='font-semibold'>Tag</label>
                            <TextAreaInput name="extraValue1" className={"h-32 resize-none"}
                                value={state.extraValue1} onChange={handle_change} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='font-semibold'>Keyword 2</label>
                            <TextAreaInput name="extraValue2" className={"h-32 resize-none"}
                                value={state.extraValue2} onChange={handle_change} />
                        </div>
                    </div>
                    <div className='w-full sm:w-1/4 mt-4'>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block required'>Status</label>
                            <select className='w-full h-8 py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none'
                                value={state.activated} name='activated' onChange={handle_change}>
                                <option value="1">Active</option>
                                <option value="0">Disabled</option>
                            </select>
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block required'>Publish date</label>
                            <DateInput
                                dateFormat={"dd/MM/yyyy HH:mm"}
                                selected={publishDate}
                                showTimeSelect
                                onChange={(e) => { setSelected((prev) => ({ ...prev, publishDate: e })) }} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className=''>
                                <input type='checkbox' checked={state.useCustomOrder} onChange={(e) => { setState((prev) => ({ ...prev, useCustomOrder: e.target.checked })) }} />
                                <span className='ms-2 font-semibold'>Use priority order</span>
                            </label>
                            <div className='w-full mt-2'>
                                <InputTextUnit onChange={handle_change} value={state.customOrder} name='customOrder'
                                    label='' required={false} disabled={!state.useCustomOrder} />
                                <span className='text-gray-600 py-3 block'>Note: Order by Descending</span>
                            </div>
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className=''>
                                <input type='checkbox' checked={showInHome} onChange={(e) => { setSelected((prev) => ({ ...prev, showInHome: e.target.checked })) }} />
                                <span className='ms-2 font-semibold'>Show in home page</span>
                            </label>
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block w-full font-semibold'>Image</label>
                            <div className='border border-dashed border-slate-500'>
                                <div className='flex'>
                                    <div className='relative w-1/2'>
                                        <button type="button" className="bg-slate-300 size-24 text-center m-2 rounded-sm font-semibold box-border cursor-pointer" onClick={handleUpload}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <span className='mt-2 block'>Click to upload</span>
                                        </button>
                                        <input
                                            type="file"
                                            ref={fileUpload}
                                            onChange={upload_news_image}
                                            className="absolute w-full opacity-0 inset-0 cursor-pointer"
                                        />
                                    </div>
                                    <div className='w-1/2 p-3'>
                                        <p>{`Attached image < 2MB`}</p>
                                    </div>
                                </div>
                                <div className='w-full px-3 relative'>
                                    {CommonHelper.is_not_empty(state.image) && (
                                        <>
                                            <div className='rounded-sm overflow-hidden w-full py-2 flex items-center flex-nowrap'>
                                                <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + state.image} className='w-full object-cover' />
                                            </div>
                                            <a className='absolute cursor-pointer top-1 right-1' onClick={remove_image}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full my-14 px-2 sm:px-4'>
                    <div>
                        <button type='button' onClick={handle_submit}
                            className='inline-flex w-32 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset bg-cyan-900'>
                            Submit</button>

                        <button type='button' onClick={handle_reset}
                            className='inline-flex ms-5 w-32 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-color-primary shadow-sm ring-1 ring-inset bg-slate-300'>
                            Reset form</button>
                    </div>
                    {isError && (
                        <span className='text-red-600 py-3 block'>Update failed</span>
                    )}
                    {isSuccess && (
                        <span className='text-green-600 py-3 block'>Update successful</span>
                    )}
                </div>
            </div>
            {onSearch && (
                <div className='absolute inset-0 p-2 bg-white'>
                    <AdminNewsSearch is_show={onSearch} onEdit={(e) => { setCommonState((prev) => ({ ...prev, onSearch: false })) }} />
                </div>
            )}
        </>
    )
}

export default AdminNewsPage