import api from "./api";
import axios from "axios";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const adminApi = {
    post_page_content: async (req) => {
        //req["AppSite"] = process.env.REACT_APP_CODE;
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            req = {
                ...req, session_id,
                AppSite: process.env.REACT_APP_CODE
            };
            var callApi = await api.post("admin/post_page_content", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    get_page_content: async (id) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            /*var _url = `${process.env.REACT_APP_CODE}`;
            _url += "?id=" + id;
            _url += "&session_id=" + session_id;*/
            var callApi = await api.get("admin/" + id + "?session_id=" + session_id);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
        }
        return callApi;
    },
    get_page_contents: async () => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.get("admin/get_page_contents?session_id=" + session_id);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
        }
        return callApi;
    },
    get_landing_page_preview: async () => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.get("admin/get_landing_page_preview?session_id=" + session_id);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
        }
        return callApi;
    },
    upload_image_to_page_content_editor: async (req) => {
        var token = await CommonHelper.get_token();
        var app = await CommonHelper.get_app();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        if (CommonHelper.is_not_empty(session_id)) {
            const formData = new FormData();
            formData.append("session_id", session_id);
            formData.append("app_site", process.env.REACT_APP_CODE);
            formData.append("news_image", req.file)
            var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "admin/upload_image_to_page_content_editor", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            return response;
        }
        return null;
    },
}

export default adminApi;