import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
//import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import exhibitorApi from '../../api/exhibitorApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faEye, faHome, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import adminApi from '../../api/adminApi';
import { organizerActions } from '../../_store/organizer.slice';
import CommonHelper from '../../helpers/common';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
/*
ClassicEditor.create(document.querySelector('#editor'), {
  plugins: [GeneralHtmlSupport],
  htmlSupport: {
    allow: [
      {
        attributes: true,
        classes: true,
        styles: true
      }
    ],
  }
})*/

function AdminLandingPage() {
  const dispatch = useDispatch();
  const [state, setState] = useState([]);

  useEffect(() => {
    get_list()
  }, []);

  const handle_new = () => {
    window.location.href = "/admin/landing-page/content";
  }
  const get_list = () => {
    dispatch(organizerActions.set_loading());
    const response = adminApi.get_page_contents();
    response.then((res) => {
      console.log(res);
      dispatch(organizerActions.set_loading());
      if (res.code == 200) {
        setState(res.data);
      }
    })
  }
  const handle_edit = (id) => {
    window.location.href = "/admin/landing-page/content/" + id;
  }
  return (
    <>
      <div className='p-3 bg-white'>
        <div className='breadscum w-full'>
          <div className='w-1/2'>
            <ul className='flex flex-wrap'>
              <li className='text-sm '>
                <a href="/admin"><FontAwesomeIcon icon={faHome} /> </a>
              </li>
              <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                <a href="/admin/landing-page">Landing page </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='w-full mt-3'>
          <button type='button' className='mt-5 ml-5 rounded-2xl text-white py-1 px-4 box-border text-lg bg-cyan-600'
            onClick={handle_new}> <FontAwesomeIcon icon={faPlus} /> Thêm nội dung </button>
          <a href='/admin/landing-page/preview' target='_blank'
            className='mt-5 ml-5 rounded-2xl text-white py-2 px-4 box-border text-lg bg-orange-500'
          > <FontAwesomeIcon icon={faEye} /> Xem trước </a>
        </div>
        <div className='mt-3 min-h-96'>
          {state.length > 0 && (
            <>
              <table className='border-collapse border border-slate-400 w-full'>
                <thead>
                  <tr>
                    <th className='border border-slate-300 w-1/12 ps-2 text-center'>#</th>
                    <th className='border border-slate-300 w-1/4 ps-2 text-center'>Tiêu đề</th>
                    <th className='border border-slate-300 w-1/6 ps-2 text-center'>Loại</th>
                    <th className='border border-slate-300 w-1/6 ps-2 text-center'>Ngày hiệu lực</th>
                    <th className='border border-slate-300 w-1/12 ps-2 text-center'>Trạng thái</th>
                    <th className='border border-slate-300 w-1/12'></th>
                  </tr>
                </thead>
                <tbody>
                  {state.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className='border border-slate-300 w-8 pe-2 text-end'>
                          <div className='w-4/5 mx-auto'>{i + 1}</div>
                        </td>
                        <td className='border border-slate-300 ps-2'>{item.title}</td>
                        <td className='border border-slate-300 ps-2'>{item.type == 1 ? "Editor" : "Slide"}</td>
                        <td className='border border-slate-300 ps-2'>{CommonHelper.convertToDDMMYYYYHHMM(item.fromDate)}</td>
                        <td className='border border-slate-300 ps-2 text-center'>
                          {item.status == 1 && (
                            <FontAwesomeIcon icon={faCheck} className='text-green-700' />
                          )}
                          {item.status == 0 && (
                            <FontAwesomeIcon icon={faBan} className='text-red-700' />
                          )}
                        </td>
                        <td className='border border-slate-300 ps-2'>
                          <div className='flex'>
                            <a className='block flex-auto cursor-pointer text-center' onClick={() => { handle_edit(item.id) }}>
                              <FontAwesomeIcon icon={faPencil} />
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}
          {state.length == 0 && (
            <div className='mt-10 border py-10'>
              <h2 className='text-red-500 font-semibold text-xl text-center'>NO DATA</h2>
            </div>
          )}

        </div>
      </div>
    </>
  )
}

export default AdminLandingPage