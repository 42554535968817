import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonHelper from '../../../../helpers/common';
import { organizerActions } from '../../../../_store/organizer.slice';
import adminApi from '../../../../api/adminApi';
import PropTypes from 'prop-types'

function AdminLandingPagePreivew(props) {
    const [state, setState] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        get_preview();
    }, []);
    const get_preview = () => {
        dispatch(organizerActions.set_loading());
        const response = adminApi.get_landing_page_preview();
        response.then((res) => {
            console.log(res)
            if (res.code == 200) {
                setState(res.data);
            }
            dispatch(organizerActions.set_loading());
        })
    }
    return (
        <>
            {state.length > 0 && state.map((item, i) => (
                <div key={i}>
                    {CommonHelper.convertStringToHTML(item.content)}
                </div>
            ))}
        </>
    )
}

AdminLandingPagePreivew.propTypes = {}

export default AdminLandingPagePreivew
