import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLoaderData } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonHelper from '../../../../helpers/common';
import InputTextUnit from '../../../../components/controls/input-text-unit';
import SelectUnit from '../../../../components/controls/select-unit';
import TextAreaInput from '../../../../components/controls/textarea-input';
import AutocompleteInput from '../../../../components/controls/autocomplete-input';
import ValidateMessage from '../../../../components/controls/validate-msg';
import { organizerActions } from '../../../../_store/organizer.slice';
import PropTypes from 'prop-types'
import { faHome } from '@fortawesome/free-solid-svg-icons';
import DateInput from '../../../../components/controls/date-input';
import adminApi from '../../../../api/adminApi';

export async function loader({ params }) {
    // var response = await exhibitorApi.get_exhibitor(params.id);
    // if (response != null) {
    //     return response;
    // }
    return params;
}
const initial = {
    "id": 0,
    "pageId": 'LandingPage',
    "title": '',
    "sectionId": 0,
    "type": 1,
    "content": '',
    "largeImage": '',
    "thumbImage": '',
    "fromDate": '',
    "toDate": '',
    "order": 0,
    "status": 0,
    "createdBy": '',
    "createdDate": '',
    "modifiedBy": '',
    "modifiedDate": '',
}
function AdminPageContent(props) {
    const dispatch = useDispatch();
    const fileUpload = useRef(null);
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        var _data = {
                            file
                        };
                        var response = adminApi.upload_image_to_page_content_editor(_data);
                        response.then((res) => {
                            if (res.status === 200) {
                                if (res.data.code === 202 && res.data.data != null) {
                                    resolve({ default: `${process.env.REACT_APP_EXHIBITOR_IMAGE}${res.data.data.img_url}` })
                                }
                            }
                        }).catch((err) => {
                            reject(err);
                        })
                    })
                })
            }
        }
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }
    const obj_params = useLoaderData();
    const [state, setState] = useState(initial)
    const [{ stateError, errorMsg, isLoading, isError, isSuccess, isEdit,
        onSearch, lst_venues, fm_dt, to_dt }, setCommonState] = useState({
            stateError: {
                title: false,
                content: false,
            },
            errorMsg: {
                title: "",
                content: "",
            },
            isLoading: true,
            isError: false,
            isSuccess: false,
            isEdit: false,
            onSearch: false,
            fm_dt: new Date(),
            to_dt: null,
        })
    useEffect(() => {
        if (obj_params != undefined) {
            setState((prev) => ({ ...prev, id: obj_params.id }));
            dispatch(organizerActions.set_loading());
            const response = adminApi.get_page_content(obj_params.id);
            response.then((res) => {
                console.log(res);
                if (res != null) {
                    if (res.code == 200) {
                        var _data = res.data;
                        var fm_dt = null;
                        var to_dt = null;
                        if (CommonHelper.is_not_empty(_data.fromDate)) {
                            fm_dt = CommonHelper.parseStringToDate(_data.fromDate, "yyyyMMdd HHmmss")
                        }
                        if (CommonHelper.is_not_empty(_data.toDate)) {
                            to_dt = CommonHelper.parseStringToDate(_data.toDate, "yyyyMMdd HHmmss")
                        }
                        setState((prev) => ({
                            ...prev,
                            "id": _data.id,
                            "pageId": _data.pageId,
                            "title": _data.title,
                            "sectionId": _data.sectionId,
                            "type": _data.type,
                            "content": _data.content,
                            "largeImage": _data.largeImage,
                            "thumbImage": _data.thumbImage,
                            "fromDate": _data.fromDate,
                            "toDate": _data.toDate,
                            "order": _data.order,
                            "status": _data.status,
                            "createdBy": _data.createdBy,
                            "createdDate": _data.createdDate,
                            "modifiedBy": _data.modifiedBy,
                            "modifiedDate": _data.modifiedDate,
                        }))
                        setCommonState((prev) => ({ ...prev, fm_dt, to_dt }));
                    }
                }
                dispatch(organizerActions.set_loading());
            })
        } else {
            //setCommonState((prev) => ({ ...prev, fm_dt: new Date() }))
            setState((prev) => ({ ...prev, fromDate: CommonHelper.formatToYYYYMMDDHHMMSS(fm_dt) }));
        }
    }, [])
    useEffect(() => {
        //console.log(obj_params)
        var fromDate = "";
        var toDate = "";
        if (fm_dt != null) {
            fromDate = CommonHelper.formatToYYYYMMDDHHMMSS(fm_dt);
        }
        if (to_dt != null) {
            toDate = CommonHelper.formatToYYYYMMDDHHMMSS(to_dt);
        }
        setState((prev) => ({ ...prev, fromDate, toDate }));
    }, [fm_dt, to_dt])
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({
            ...prev,
            [name]: value,
        }))
        validate_required(e);
    }
    const validate_required = (e) => {
        const { name, value } = e.target;
        var _arr = [];
        Object.keys({ ...stateError }).map((item) => {
            _arr.push(item);
        })
        var _idx = _arr.indexOf(name);
        if (_idx >= 0) {
            setCommonState((prev) => ({
                ...prev,
                stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
            }))
        }
    }
    const handle_submit = () => {
        var data = { ...state };
        dispatch(organizerActions.set_loading());
        const response = adminApi.post_page_content(data);
        response.then((res) => {
            if (res != null) {
                if (res.code == 204) {
                    dispatch(organizerActions.set_loading());

                } else if (res.code == 506) {
                    alert("Phiên làm việc hết hiệu lực. Vui lòng đăng nhập lại!")
                    const timer = setTimeout(() => {
                        window.location.href = "/admin/login"
                    }, 4000);
                    return () => clearTimeout(timer);
                }
            }
        })
    }
    const handle_reset = () => {
        var fm_dt = new Date()
        var fromDate = CommonHelper.formatToYYYYMMDDHHMMSS(fm_dt);
        setCommonState((prev) => ({ ...prev, to_dt: null, fm_dt }));
        setState((prev) => ({
            ...prev,
            "id": initial.id,
            "pageId": initial.pageId,
            "title": initial.title,
            "sectionId": initial.sectionId,
            "type": initial.type,
            "content": initial.content,
            "largeImage": initial.largeImage,
            "thumbImage": initial.thumbImage,
            "toDate": initial.toDate,
            "order": initial.order,
            "status": initial.status,
            "createdBy": initial.createdBy,
            "createdDate": initial.createdDate,
            "modifiedBy": initial.modifiedBy,
            "modifiedDate": initial.modifiedDate,
            fromDate,
        }))
    }
    return (
        <>
            <div className='p-3 bg-white'>
                <div className='breadscum w-full'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <a href={"/admin"}><FontAwesomeIcon icon={faHome} /> </a>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <a href={"/admin/landing-page"}>Landing page </a>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <a href={"/admin/landing-page/content"}>Content </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex'>
                    <div className='w-2/3'>
                        <h6 className='text-color-primary font-semibold text-xl'>{state.title}</h6>
                    </div>
                    <div className='w-1/3'></div>
                </div>
                <div className='block sm:flex sm:flex-wrap'>
                    <div className='w-full sm:w-3/4 mt-4'>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.title} name='title'
                                label='Tiêu đề' required={true} isError={stateError.title}
                                errorMsg={errorMsg.title} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='font-semibold'>Nội dung</label>
                            <CKEditor
                                config={{
                                    extraPlugins: [uploadPlugin],
                                    htmlSupport: {
                                        allow: [
                                            { attributes: true, classes: true, styles: true }
                                        ]
                                    }
                                }}
                                editor={Editor}
                                data={state.content}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(editor);
                                }}
                                onChange={(event, editor) => {
                                    setState((prev) => ({ ...prev, content: editor.getData() }))
                                }}
                            />
                        </div>
                    </div>
                    <div className='w-full sm:w-1/4 mt-4'>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block required'>Trạng thái</label>
                            <select className='w-full h-8 py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none'
                                value={state.status} name='status' onChange={handle_change}>
                                <option value="1">Hiển thị</option>
                                <option value="0">Khóa</option>
                            </select>
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block required'>Ngày bắt đầu</label>
                            <DateInput
                                dateFormat={"dd/MM/yyyy HH:mm"}
                                minDate={new Date()}
                                maxDate={to_dt}
                                selected={fm_dt}
                                showTimeSelect
                                onChange={(e) => { setCommonState((prev) => ({ ...prev, fm_dt: e })) }} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block'>Ngày kết thúc</label>
                            <DateInput
                                dateFormat={"dd/MM/yyyy HH:mm"}
                                minDate={fm_dt}
                                selected={to_dt}
                                showTimeSelect
                                onChange={(e) => { setCommonState((prev) => ({ ...prev, to_dt: e })) }} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.order} name='order'
                                label='Số thứ tự' required={false} />
                        </div>
                    </div>
                </div>

                <div className='w-full my-14 px-2 sm:px-4'>
                    <div>
                        <button type='button' onClick={handle_submit}
                            className='inline-flex w-32 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset bg-cyan-900'>
                            Lưu</button>

                        <button type='button' onClick={handle_reset}
                            className='inline-flex ms-5 w-32 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-color-primary shadow-sm ring-1 ring-inset bg-slate-300'>
                            Nhập lại</button>
                    </div>
                    {isError && (
                        <span className='text-red-600 py-3 block'>Update failed</span>
                    )}
                    {isSuccess && (
                        <span className='text-green-600 py-3 block'>Update successful</span>
                    )}
                </div>
            </div>
        </>
    )
}

AdminPageContent.propTypes = {}

export default AdminPageContent
